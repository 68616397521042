import "./App.css";
import LandingPage from "./LandingPage";
import Footer from "./component/Footer";
import Header from "./component/Header";
import React from "react";
import Maintaince from "./pages/Maintaince";

function App() {
  const maintaince = true;

  return maintaince ? (
    <Maintaince />
  ) : (
    <div className="App">
      <Header />
      <LandingPage />
      <Footer />
    </div>
  );
}

export default App;
