import React from "react";
import "./Maintaince.css";

function Maintaince() {
  return (
    <div className="maintenance">
      {/* <h1>Comming Soon</h1> */}{" "}
      <img className="bussinessImage" src="/assets/Business Team.gif" alt="" />
      <h1>We're working hard to bring you something amazing. Stay tuned!</h1>
    </div>
  );
}

export default Maintaince;
